import Vue from "vue";

export const invoices = (queryParams) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/invoices/`,
        params: queryParams,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const viewInvoice = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/invoices/${payload.id}/view-invoice/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const downloadInvoice = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/invoices/${payload.id}/download-invoice/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });
