<template>
  <transition
    enter-class="opacity-0"
    enter-active-class="transition-opacity duration-700 ease-linear"
    leave-class="opacity-0"
    leave-active-class="transition-opacity duration-700 ease-linear"
  >
    <div
      class="text-white text-sm relative font-medium text-justify max-w-2xl m-auto rounded-lg shadow p-5 mb-4 overflow-hidden bg-red-600"
    >
      {{ message }}
    </div>
  </transition>
</template>

<script>
export default {
  name: "uiToast",
  props: {
    position: {
      type: String,
      validator: (value) =>
        [
          "top",
          "top-left",
          "top-right",
          "bottom",
          "bottom-left",
          "bottom-right",
        ].includes(value),
      default: "bottom",
    },
    message: {
      type: String,
      default: null,
    },
    displayTime: {
      type: Number,
      default: 2000,
    },
    delayTime: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    let toastContainer = document.querySelector(".toastr");
    if (!toastContainer) {
      toastContainer = document.createElement("div");
      toastContainer.classList.add("toastr");
      toastContainer.classList.add(`${this.position}`);
      document.body.appendChild(toastContainer);
    }
    toastContainer.appendChild(this.$el);

    // Display toastr with delayTime milliseconds
    setTimeout(() => this.showToastr(), this.delayTime);
  },
  data() {
    return {
      id: null,
    };
  },
  methods: {
    showToastr() {
      this.id = setTimeout(() => this.hideToastr(), this.displayTime);
    },
    hideToastr() {
      clearTimeout(this.id);

      setTimeout(() => {
        this.$destroy();
        this.$el.remove();
      }, 150);
    },
  },
};
</script>

<style lang="scss">
// Important here: DO NOT WRAP THIS BLOCK IN SCOPED SEMANTICS BECAUSE WON'T WORK
.toastr {
  @apply items-center align-middle absolute z-[999999] w-full pointer-events-none;

  &.top {
    @apply top-5;
  }
  &.top-left {
    @apply top-5;

    > div {
      @apply ml-5;
    }
  }
  &.top-right {
    @apply top-5;
    > div {
      @apply mr-5;
    }
  }
  &.bottom {
    @apply bottom-5;
  }
  &.bottom-left {
    @apply bottom-5;

    > div {
      @apply ml-5;
    }
  }
  &.bottom-right {
    @apply bottom-5;

    > div {
      @apply mr-5;
    }
  }
}
</style>
