import Vue from "vue";

export const contracts = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: "/contracts/",
        params: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const contract = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/contracts/${payload.id}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });
