/* Helper methods to manage auth accordingly */

import jwt_decode from "jwt-decode";
/**
 * Decode Json Web Token to fetch encoded data easily
 */
const decodedToken = (token) => {
  return jwt_decode(token);
};

const getToken = (tokenName) => {
  return localStorage.getItem(tokenName || "access_token");
};

const saveToken = (tokenName, value) => {
  localStorage.setItem(tokenName, value);
};

const removeToken = (tokenName) => {
  localStorage.removeItem(tokenName);
};

const removeAuthTokens = () => {
  removeToken("access-token");
  removeToken("refresh-token");
};

const VueAuthentication = (Vue) => {
  // Add here new prototype
  Vue.prototype.$decodedToken = decodedToken;
  Vue.prototype.$getToken = getToken;
  Vue.prototype.$saveAccessToken = (tokenValue) => {
    return saveToken("access-token", tokenValue);
  };
  Vue.prototype.$saveRefreshToken = (tokenValue) => {
    return saveToken("refresh-token", tokenValue);
  };
  Vue.prototype.$removeToken = removeToken;
  Vue.prototype.$removeTokens = removeAuthTokens;
};

export default VueAuthentication;
