import Vue from "vue";

export const countries = () =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: "/countries/",
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });
