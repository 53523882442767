<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { getLanguageCode } from "@/utils/language";

export default {
  name: "App",
  mounted() {
    // Set the default site language
    this.$root.$options.i18n.locale = getLanguageCode(location.hostname);
  },
};
</script>

<style lang="scss" module>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 0;
}
</style>
