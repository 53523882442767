import axios from "axios";
import Vue from "vue";
import { requestInterceptor, responseErrorInterceptor } from "./interceptors";

const createClient = (baseUrl) => {
  return axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
  });
};

export const clientAuth = createClient(process.env.VUE_APP_ROOT_API);
const clienNoAuth = createClient(process.env.VUE_APP_ROOT_API);

// Set request interceptors
clientAuth.interceptors.request.use(requestInterceptor);

// Set response interceptors
clientAuth.interceptors.response.use(
  (response) => Promise.resolve(response),
  responseErrorInterceptor
);

// Export clients as Vuejs plugin
const install = () => {
  Vue.prototype.$clientAuth = clientAuth;
  Vue.prototype.$clientNoAuth = clienNoAuth;
};

export default install;
