import Vue from "vue";

export const login = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientNoAuth({
        method: "post",
        url: "/auth/login/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const refreshToken = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: "/auth/refresh/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const firebaseToken = () =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: "/auth/remote-token/",
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const loggedUser = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/users/${payload.id}/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const recoveryPassword = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientNoAuth({
        method: "post",
        url: "/auth/password/reset/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const recoveryPasswordConfirm = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientNoAuth({
        method: "post",
        url: "/auth/password/reset/confirm/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const changePassword = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "post",
        url: "/auth/password/change/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const activateAccount = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientNoAuth({
        method: "post",
        url: "/activate-account/",
        data: payload,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });
