var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition",
    {
      attrs: {
        "enter-class": "opacity-0",
        "enter-active-class": "transition-opacity duration-700 ease-linear",
        "leave-class": "opacity-0",
        "leave-active-class": "transition-opacity duration-700 ease-linear",
      },
    },
    [
      _c(
        "div",
        {
          staticClass:
            "text-white text-sm relative font-medium text-justify max-w-2xl m-auto rounded-lg shadow p-5 mb-4 overflow-hidden bg-red-600",
        },
        [_vm._v(" " + _vm._s(_vm.message) + " ")]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }