import uiToast from "./uiToast.vue";
import uiToastNotification from "./uiToastNotification.vue";

uiToast.install = (Vue, options = {}) => {
  function showToast(obj) {
    const ToastComponent = Vue.extend(uiToast);
    const propsData = Object.assign(options, obj);
    const component = new ToastComponent({
      el: document.createElement("div"),
      propsData,
    });
    return component;
  }

  function showToastNotification(obj) {
    const ToastComponent = Vue.extend(uiToastNotification);
    const propsData = Object.assign(options, obj);
    const component = new ToastComponent({
      el: document.createElement("div"),
      propsData,
    });
    return component;
  }

  // Contract with public method for this component
  Vue.prototype.$toast = (obj) => showToast(obj);
  Vue.prototype.$toastNotification = (obj) => showToastNotification(obj);
};

if (typeof window !== "undefined" && window.Vue) {
  window.Vue.use(uiToast);
  window.Vue.use(uiToastNotification);
}

export default uiToast;
