import Vue from "vue";

export const creditNotes = (queryParams) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/credit-notes/`,
        params: queryParams,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const viewCreditNote = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/credit-notes/${payload.id}/view-credit-note/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });

export const downloadCreditNote = (payload) =>
  new Promise((resolve, reject) => {
    Vue.prototype
      .$clientAuth({
        method: "get",
        url: `/credit-notes/${payload.id}/download-credit-note/`,
      })
      .then(
        (response) => {
          resolve(response);
        },
        (error) => {
          reject(error);
          throw new Error(error);
        }
      );
  });
