const checkI18nKey = (Vue) => {
  // Add here new prototype
  // Important not use arrow functions to get access to 'this'
  Vue.prototype.$checkTranslation = function (key) {
    return this.$te(key) || this.$root.$options.i18n.te(key);
  };
};

export default checkI18nKey;
export { checkI18nKey };
